import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = ({ mobileOpen }) => (
  <div onClick={mobileOpen} className='close-icon' />
);

CloseIcon.propTypes = {
  mobileOpen: PropTypes.func
};

export default CloseIcon;
