import React from 'react';

const ArrowIcon = () => {
  return (
    <div className='arrow-icon-cont'>
      <a
        href='#'
        onClick={() => {
          scroll(0, 0);
          return false;
        }}
        title='To top'
      >
        <div className='arrow-icon' />
      </a>
    </div>
  );
};

export default ArrowIcon;
