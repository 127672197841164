import React from 'react';
import PropTypes from 'prop-types';

const Burger = ({ mobileOpen }) => (
  <div className='nav-btn' onClick={mobileOpen}>
    <span />
  </div>
);

Burger.propTypes = {
  mobileOpen: PropTypes.func
};

export default Burger;
