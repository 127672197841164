/* eslint-disable no-undef */
import React, { useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';

import { HEADER_TOKENS as HEADER_LINKS } from '../../constants/Languages';
import Button from '../Common/Button/Button';
import Burger from './Burger';
import LinkImage from '../Common/LinkImage';
import HeaderMenu from './HeaderMenu';

import './header.scss';

const Header = () => {
  const [isMobileOpen, mobileOpen] = useState(false);

  function menuOpen() {
    mobileOpen(isMobileOpen => !isMobileOpen);
  }
  return (
    <StaticQuery
      query={graphql`
        query {
          logo: file(relativePath: { eq: "PRO.svg" }) {
            publicURL
          }
          wallet_icon: file(relativePath: { eq: "wallet-icon.png" }) {
            childImageSharp {
              fluid(maxWidth: 23, maxHeight: 20) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          pages: allMarkdownRemark(
            limit: 2000
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { fileAbsolutePath: { regex: "/content/" } }
          ) {
            totalCount
            edges {
              node {
                frontmatter {
                  title
                  path
                  tags
                }
              }
            }
          }
        }
      `}
      render={data => {
        const {
          pages: { edges }
        } = data;
        let secondHeaderLinks = [];
        edges.forEach(
          ({
            node: {
              frontmatter: { title, path, tags }
            }
          }) => {
            if ((tags || []).includes('secondary_header')) {
              secondHeaderLinks.push({ title, path });
            }
          }
        );
        return (
          <section className='header-container'>
            <header className='header'>
              <div className='header-left-side'>
                <LinkImage
                  src={data.logo.publicURL}
                  alt='Check Emails'
                  href='/'
                  imgClassName='header-logo'
                  linkClassName='header-logo-cont'
                  tabIndex={1}
                />
              </div>
              <div className='header-right-side'>
                <HeaderMenu
                  primaryLinks={HEADER_LINKS}
                  secondary_links={secondHeaderLinks}
                  isMobileOpen={isMobileOpen}
                  mobileOpen={menuOpen}
                />

                <Burger mobileOpen={menuOpen} isMobileOpen={isMobileOpen} />

                <Button
                  className='btn btn-nav'
                  text='Sign Up'
                  link={
                    '//track.emailcheckerpro.com/click.php?key=emailcheckerpro.com'
                  }
                />
              </div>
            </header>
          </section>
        );
      }}
    />
  );
};

export default Header;
